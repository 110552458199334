.chat-bot {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 78px;
  // background: #5865f2;
  border-radius: 50%;
  position: fixed;
  bottom: 35px;
  right: 10px;
  bottom: 20px;
  z-index: 1000;

  .imgchatbot {
    width: 60px;
  }

  .your-chats {
    position: fixed;
    z-index: 10;
    bottom: -100%;
    /* Start from bottom */
    right: 5%;
    width: 300px;
    height: 498px;
    border-radius: 10.593px;
    background: #fff;
    transition: bottom 0.5s ease-in-out;
    /* Transition for smooth sliding */
    cursor: auto;

    &.show {
      bottom: 13% !important;
      /* Slide up */
    }
  }

  img {
    transition: transform 0.5s ease-in-out;
  }

  img.rotate {
    transform: rotate(90deg);
  }

  .your-chats-top {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    border-radius: 10.593px 10.593px 0px 0px;
    background: #5865f2;
    height: 60px;
    cursor: pointer;
    width: 278px;

    p {
      color: #fff;
      font-family: "Inter";
      font-size: 15.889px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .your-chats-second {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 278px;
    height: 49px;
    border-bottom: 1px solid #cbcbcb;
    padding: 0 10px;

    h2 {
      color: #525252;
      font-family: "Inter";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.552px;
      /* 146.264% */
      letter-spacing: -0.276px;
    }

    p {
      color: #8e8e93;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.125px;
      margin-top: 5px;
    }
  }

  .your-chats-last {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 10px;
    width: 278px;
    height: 49px;
    border-bottom: 1px solid #cbcbcb;

    .last-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h2 {
        color: #525252;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17.552px;
        /* 146.264% */
        letter-spacing: -0.276px;
      }

      p {
        color: #515151;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.125px;
        margin-top: 5px;
      }
    }
  }

  .lawvocate-ai {
    position: fixed;
    z-index: 10;
    bottom: -100%;
    /* Start from bottom */
    right: 5%;
    width: 400px;
    // height: 600px;
    border-radius: 10.593px;
    background: #fff;
    transition: bottom 0.5s ease-in-out;
    /* Transition for smooth sliding */
    cursor: auto;

    &.show {
      bottom: 13%;
      box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.3);
      /* Slide up */
    }

    .lawvocate-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      border-radius: 10.127px 10.127px 0px 0px;
      background: #304e8d;
      height: 57.597px;
      padding-left: 11.393px;

      .lawvocate-top-left {
        display: flex;
        gap: 10px;

        img {
          transform: rotate(360deg);
        }

        p {
          color: #fff;
          font-family: "Inter";
          font-size: 15.889px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .lawvocate-messages {
      padding: 13px 27px;

      .message-container {
        border-radius: 3.798px 3.798px 3.798px 0px;
        background: rgba(48, 78, 141, 0.21);
        box-shadow: 0px 0px 6.329px 0px rgba(0, 0, 0, 0.1);
        width: fit-content;
        padding: 7px;
        margin: 0px !important;

        margin-top: 7px !important;

        p {
          color: #000;
          font-family: "Inter";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0rem !important;
        }
      }
    }

    .request-demo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-bottom: 25px;

      .request-btn {
        cursor: pointer;
        border-radius: 3.798px;
        border: 0.633px solid #304e8d;
        padding: 6.329px;
        margin-top: 7px;
        min-width: 160px;
        p {
          color: #304e8d;
          font-family: "Inter";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0rem !important;
        }
      }
    }

    .message-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80%;
      background: #fff;
    }

    .write-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-top: 0.633px solid rgba(132, 132, 132, 0.5);
      padding: 6.329px;
      margin-top: 10px;

      span {
        color: #848484;
        font-family: "Inter";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      // input {
      //     border-radius: 16.636px;
      //     border: 1px solid #e8e8e8;
      //     width: 220.273px;
      //     padding: 10px 10px;
      //     outline: none;
      //     color: #6a6a6a;
      //     font-family: "Inter";
      //     font-size: 10.593px;
      //     font-weight: 400;

      //     &::placeholder {
      //         color: #6a6a6a;
      //         font-family: "Inter";
      //         font-size: 10.593px;
      //         font-weight: 400;
      //     }
      // }

      // .send-message {
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     width: 29.592px;
      //     height: 29.592px;
      //     border-radius: 50%;
      //     background: #5865f2;
      //     padding: 5px;
      //     cursor: pointer;

      //     img {
      //         width: 17px;
      //         height: 17px;
      //         transform: rotate(360deg);
      //     }
      // }
    }
  }
}

@media screen and (max-width: 1300px) {
  .chat-bot {
    bottom: 15px;
    width: 70px;
    height: 70px;

    .lawvocate-ai {
      height: 430px;

      .message-section {
        height: 75%;
      }
    }

    .lawvocate-ai.show {
      bottom: 15%;
    }

    .your-chats {
      height: 430px;
    }
  }
}

@media screen and (max-width: 600px) {
  .chat-bot {
    width: 55px;
    height: 55px;
    padding: 10px;

    .imgchatbot {
      // width: 70px;
      width: 45px;
    }

    .your-chats {
      height: 450px;
    }

    .your-chats.show {
      bottom: 13%;
    }

    .lawvocate-ai {
      height: 450px;

      .message-section {
        height: 75%;
      }
    }

    .lawvocate-ai.show {
      bottom: 13%;
      width: 90%;
      right: 50%;
      transform: translateX(50%);
    }
  }
}
