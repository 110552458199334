.text-black {
  color: #000;
}

.newsletter-banner {
  // position: fixed;
  // position: absolute;
  padding: 10px;
  z-index: 3;
  background: #3e6dc4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 350px;
  padding-right: 40px;
  height: 100px;

  .leftside-newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .newsletter-form {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .email-newsletter {
      width: 400px;
      height: 47px;
      border-radius: 20px;
      // border-radius: 25px !important;
      border: none;
      padding-left: 10px;
      color: #a5a5a5;
      font-family: "Inter";
      font-size: 20px;

      &::placeholder {
        padding-left: 10px;
        color: #a5a5a5;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // text-transform: capitalize;
      }
    }

    .subscribe-newsletter {
      color: #fff;
      font-family: "Inter";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      border: none;
      padding: 12px 32px;

      background: var(--BUTTON-BG, #4176d4);
      border-radius: 20px;
    }
  }

  .newsletter-bannertext {
    color: #fff;
    text-align: center;

    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
  }
}

.hero2Section-main {
  width: 100%;
  position: relative;
  overflow: hidden;
  // padding-top: 200px;

  .herosec-primary {
    width: 100%;
    height: 100%;
    // height: calc(100vh - 100px);
    // background: #FAFDFF;
    margin-top: 100px;
    // border: 3px solid green;

    .herosection-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .leftside {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // padding: 248px 0px 267px 228px;
        // padding-left: 180px;

        .leftContent {
          // max-width: 604px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          h5 {
            color: #2b2b2b;

            font-family: "Readex Pro";
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -3.04px;

            span {
              background: var(
                --bg,
                linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              font-family: "Readex Pro";
              font-size: 42px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -3.04px;
              text-transform: capitalize;
            }
          }

          p {
            color: #707070;
            font-family: "Inter";
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.88px;
            .website {
              font-weight: 600;
            }
            a {
              text-decoration: none;
              color: #707070;
              font-weight: 600;
            }
          }

          button {
            border-radius: 8px;
            background: var(
              --gradient,
              linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
            );
            padding: 16px 32px;
            width: 154px;
            border: none;
            cursor: pointer;
            color: #fff;
            font-family: "Inter";
            font-size: 1.146vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }

      .rightside {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 20px;

        .rightcontent {
          padding: 50px;

          // max-width: 704px;
          max-width: 565px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .herotopimg {
    position: absolute;
    top: 0;
    right: 0;
    // width: 100%;
    background-repeat: no-repeat;
  }

  .herobottomimg {
    position: absolute;
    bottom: -60%;
    z-index: 10;
    left: 0;

    // width: 100%;
  }
}

@media only screen and (max-width: 1600px) {
  .hero2Section-main {
    width: 100%;
    position: relative;
    overflow: hidden;

    .herosec-primary {
      width: 100%;
      height: calc(100vh - 100px);
      // background: #FAFDFF;
      margin-top: 100px;
      // border: 3px solid green;

      .herosection-content {
        width: 100%;
        height: 100%;
        display: flex;

        .leftside {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          // padding: 248px 0px 267px 228px;
          padding-left: 20px;

          .leftContent {
            max-width: 604px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            button {
              border-radius: 8px;
              background: var(
                --gradient,
                linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
              );
              padding: 16px 32px;
              width: 154px;
              border: none;
              cursor: pointer;
              color: #fff;
              font-family: "Inter";
              font-size: 1.146vw;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }

        .rightside {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;

          .rightcontent {
            padding: 100px;

            max-width: 565px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .herotopimg {
      display: none;
    }

    .herobottomimg {
      display: none;
    }
  }

  .newsletter-banner {
    // position: fixed;
    // position: absolute;
    padding: 10px;
    z-index: 3;
    background: #3e6dc4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 200px;
    padding-right: 40px;
    height: 100px;

    .leftside-newsletter {
      display: flex;
      align-items: center;
      gap: 10px;

      .newsletter-form {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .email-newsletter {
        width: 400px;
        height: 47px;
        padding-left: 10px;
        color: #a5a5a5;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &::placeholder {
          padding-left: 10px;
          color: #a5a5a5;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .subscribe-newsletter {
        color: #fff;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
        padding: 12px 32px;
        border-radius: 20px;
        background: var(--BUTTON-BG, #4176d4);
      }
    }

    .newsletter-bannertext {
      color: #fff;
      text-align: center;

      font-family: "Inter";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.72px;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .newsletter-banner {
    // position: fixed;
    // position: absolute;
    padding: 10px;
    z-index: 3;
    background: #3e6dc4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 20px;
    padding-right: 20px;
    height: 100px;

    .leftside-newsletter {
      display: flex;
      align-items: center;
      gap: 10px;

      .newsletter-form {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .email-newsletter {
        width: 300px;
        height: 37px;
        padding-left: 10px;
        color: #a5a5a5;
        font-family: "Inter";
        font-size: 17px;

        &::placeholder {
          padding-left: 10px;
          color: #a5a5a5;
          font-family: "Inter";
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .subscribe-newsletter {
        color: #fff;
        font-family: "Inter";
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
        padding: 10px 20px;
        border-radius: 20px;
        background: var(--BUTTON-BG, #4176d4);
      }
    }

    .newsletter-bannertext {
      color: #fff;
      text-align: center;

      font-family: "Inter";
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .hero2Section-main {
    width: 100%;
    position: relative;
    overflow: hidden;

    .herosec-primary {
      width: 100%;
      height: 100%;
      // height: calc(100vh - 100px);
      // background: #FAFDFF;
      margin-top: 100px;
      // border: 3px solid green;

      .herosection-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // padding: 10px;
        padding: 10px;

        .leftside {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px 0px;
          text-align: center;
          // padding-bottom: 100px;
          // padding: 248px 0px 267px 228px;

          order: 2;

          .leftContent {
            width: 100%;
            // max-width: 604px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            h5 {
              font-size: 22px !important;

              span {
                background: var(
                  --bg,
                  linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                font-family: "Readex Pro";
                font-size: 22px !important;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -1.04px;
                text-transform: capitalize;
              }
            }

            p {
              color: #707070;

              font-family: "Inter";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.88px;
            }

            button {
              border-radius: 8px;
              background: var(
                --gradient,
                linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
              );
              padding: 16px 32px;
              width: 154px;
              border: none;
              cursor: pointer;
              color: #fff;
              font-family: "Inter";
              font-size: 1.146vw;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
              // margin-bottom: 40px;
            }
          }
        }

        .rightside {
          width: 100%;
          height: auto;
          // height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          // padding: 10px;
          display: none;

          .rightcontent {
            padding: 0px;
            width: 100%;
            // max-width: 704px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .herotopimg {
      display: none;
    }

    .herobottomimg {
      display: none;
    }
  }

  .newsletter-banner {
    // position: fixed;
    // position: absolute;
    padding: 10px;
    z-index: 3;
    background: #3e6dc4;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 10px;
    padding-right: 5px;
    height: 100%;
    // height: 100px;

    .leftside-newsletter {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .newsletter-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }
      .email-newsletter {
        width: 300px;
        height: 37px;
        color: #a5a5a5;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-left: 10px;

        &::placeholder {
          padding-left: 10px;
          color: #a5a5a5;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .subscribe-newsletter {
        color: #fff;
        font-family: "Inter";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
        padding: 10px 20px;
        border-radius: 20px;
        background: var(--BUTTON-BG, #4176d4);
        width: 100%;
      }
    }

    .newsletter-bannertext {
      color: #fff;
      text-align: center;

      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
    }
  }
}
