.price-card {
  h3 {
    text-align: center;
    padding-bottom: 20px;
  }
  .card-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .first-card {
      max-width: 440.333px;
      flex-shrink: 0;
      border-radius: 24px;
      background: #fff;
      box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.3);
      padding: 63px 21px 50px 21px;
      position: relative;
      .card-title {
        margin-bottom: 10px;
        text-align: center;

        span {
          color: #707070;
          font-family: "Readex Pro";
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 1.44px;
          text-transform: capitalize;
        }
        p {
          color: #707070;
          font-family: "Inter";
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.88px;
        }
      }
      .card-month {
        display: flex;
        justify-content: center;
        padding: 20px;
        width: 100%;
        border-radius: 13px;
        background: #f3f3f3;
        min-height: 90px;
        .month-heading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .dollar {
            color: #6c6c76;
            text-align: center;
            font-family: "Josefin Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
          }
          .month {
            color: #8e8e8e;
            text-align: center;
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.907px;
            text-transform: capitalize;
          }
        }
      }
      .card-bottom {
        padding-top: 65px;
        // padding-bottom: 34px;
        margin-bottom: 50px;
        .bottom-primary {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 25px;
          height: fit-content;
          .left {
            img {
              width: 25px;
            }
          }
          .right {
            span {
              color: #707070;
              font-family: "Inter";
              font-size: 17px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.88px;
              text-transform: capitalize;
            }
          }
        }
      }
      .card-button {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 20px;
        button {
          padding: 10.667px 42.667px;
          border-radius: 8px;
          background: var(--BUTTON-BG, #4176d4);
          color: #fefefc;
          text-align: center;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 21.333px;
          border: none;
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .price-card {
    .card-content {
      .first-card {
        max-width: 350px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .price-card {
    .card-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .first-card {
        width: 90%;
        margin: 0 auto;
        flex-shrink: 0;
        border-radius: 24px;
        background: #fff;
        box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.3);
        padding: 63px 21px 50px 21px;
        position: relative;

        .card-title {
          margin-bottom: 10px;
          text-align: center;

          span {
            color: #707070;

            font-family: "Readex Pro";
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1.44px;
            text-transform: capitalize;
          }
        }

        .card-month {
          padding: 20px 10px;
          width: 100%;
          border-radius: 24px;
          background: #f3f3f3;
          min-height: auto;
          .month-heading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
            .dollar {
              color: #6c6c76;
              text-align: center;
              font-family: "Josefin Sans";
              font-size: 25px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: uppercase;
            }

            .month {
              color: #8e8e8e;
              text-align: center;
              font-family: Arial;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.907px;
              text-transform: capitalize;
            }
          }
        }

        .card-bottom {
          padding-top: 65px;
          // padding-bottom: 34px;
          margin-bottom: 50px;

          .bottom-primary {
            display: flex;

            align-items: center;
            gap: 10px;
            margin-bottom: 16px;
            height: fit-content;

            .left {
              img {
                width: 20px;
                height: 20px;
              }
            }

            .right {
              span {
                color: #707070;
                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.88px;
                text-transform: capitalize;
              }
            }
          }
        }

        .card-button {
          display: flex;
          justify-content: center;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          bottom: 20px;

          button {
            width: 205.333px;
            padding: 10.667px 42.667px;
            border-radius: 13.333px;
            background: var(--BUTTON-BG, #4176d4);
            color: #fefefc;
            text-align: center;
            font-family: Arial;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 21.333px;
            border: none;
            /* 100% */
          }
        }
      }
    }
  }
  .price-card .card-content .first-card .card-title p {
    font-size: 14px;
  }
}
