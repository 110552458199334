.hero-main {
  position: relative;
  width: 100%;

  .hero-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
    background: var(--secbackcolor);
    .hero-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      position: relative;
      z-index: 1;

      button {
        font-family: "Inter";
        font-size: 1.146vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        padding: 16px 32px;
        border-radius: 8px;
        border: 1.333px solid var(--gradient, #4276d4);
        // background: rgba(250, 253, 255, 0.47);
        cursor: pointer;
        position: relative;
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        color: white;

        &:hover {
          color: white;
          border-radius: 8px;
          background: var(
            --gradient,
            linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
          );
        }

        &::before {
          content: "";
          width: 100%;
          height: 20px;
          position: absolute;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
            0 1px 3px rgba(0, 0, 0, 0.08);
          bottom: -10px;
          left: 0;
          opacity: 0.5;
          background: var(
            --bg,
            linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
          );
          filter: blur(15px);
        }
      }

      h1 {
        color: var(--greycolor);
        text-align: center;
        font-family: "Readex Pro";
        font-size: 3.177vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -3.362px;
        text-transform: uppercase;
        margin: 0px;

        span {
          background: linear-gradient(92deg, #33539e -15.2%, #2f4d8a 102.24%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .herofirstimg {
    position: absolute;
    top: 0;
    right: 0;
  }

  .herosecondimg {
    position: absolute;
    bottom: -50%;
    z-index: 2;
    left: 0;
  }
}

.topscroll {
  position: fixed;
  z-index: 99;
  right: 24px;
  bottom: 110px;
  cursor: pointer;
  width: 55px;
}

@media screen and (max-width: 1280px) {
  .hero-main {
    .hero-sec {
      .hero-content {
        h1 {
          font-size: 60px;
          line-height: 100%;
        }
      }

      .hero-rot {
        .hero-img {
          width: 500px;
          height: 600px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .hero-main {
    .hero-sec {
      height: unset;
      padding: 140px 0px;

      .hero-content {
        padding: 40px 10px;
        gap: 10px;

        h1 {
          letter-spacing: 0.1px;
          font-size: 27px;
          line-height: 120%;
          width: 100% !important;
        }

        .para-small {
          font-size: 18px;
          text-align: center;
        }
      }

      .hero-rot {
        .hero-img {
          width: 100%;
          height: unset;
          object-fit: cover;
        }
      }
    }
  }

  .topscroll {
    position: fixed;
    z-index: 99;
    right: 20px;
    bottom: 80px;
    width: 40px;
    object-fit: cover;
    height: 40px;
    cursor: pointer;
  }
}
