.privacyPolicy {
  padding: 200px 20px;
}
.privacyPolicy ol {
  padding-left: 0;
}
.privacyPolicy p,
.privacyPolicy li {
  color: #676767;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.privacyPolicy ol li ul {
 
  /* border: 1px solid red; */
 display: flex;
 flex-direction: column;
  margin-top: 10px;
  list-style:circle;
  gap: 10px;
  margin-bottom: 10px;

}

.privacyPolicy ol li ul li ul{

  list-style: square;
}
.privacyPolicy p strong {
  /* color: #000; */
}
.privacyPolicy h6 {
  color: #676767;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
}
.pointer {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .privacyPolicy {
    padding: 150px 20px 20px 20px;
  }
  .privacyPolicy p {
    font-size: 13px;
  }
  .privacyPolicy h6 {
    font-size: 16px !important;
  }
  .privacyPolicy ul {
    padding-left: 20px;
  }
  .privacyPolicy li {
    font-size: 13px !important;
  }
}
