.chatagent {
  .chatagent-content {
    .chat-heading {
      // max-width: 876px;
      //   padding: 0px 522px 161px 522px;
      // padding: 60px 522px 100px 522px;

      h5 {
        color: #2b2b2b;
        text-align: center;
        font-family: "Readex Pro";
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -1.92px;
        text-transform: none;
      }
      p {
        color: #707070;
        font-family: "Inter";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.88px;
      }
    }

    .agent-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 80px;
      // padding: 0px 268px 50px 268px;
      padding: 50px 0px;
    }

    .last-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 120px;

      button {
        padding: 16px 32px;
        border-radius: 8px;
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        color: #fff;
        font-family: "Inter";
        font-size: 1.146vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .chatagent {
    .chatagent-content {
      .last-btn {
        padding-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .chatagent {
    .chatagent-content {
      .agent-cards {
        gap: 30px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .chatagent {
    .chatagent-content {
      .chat-heading {
        padding-top: 20px;
        h5 {
          font-size: 22px !important;
        }
      }

      .agent-cards {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        padding: 0px 20px 50px 20px;
      }

      .last-btn {
        padding-bottom: 20px;
      }
    }
  }
}
