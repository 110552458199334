.footer-section {
  position: relative;

  .footer-content {
    width: 100%;

    .footer {
      padding: 70px 100px 133px 100px;
      display: flex;
      justify-content: space-between;

      .first {
        // width: 25%;
        .first-content {
          max-width: 418px;

          .first-para {
            color: #5b5b5b;
            font-family: "Inter";
            font-size: 18.667px;
            font-style: normal;
            font-weight: 400;
            line-height: 167.011%;
            /* 31.175px */
            letter-spacing: 0.747px;
            padding-top: 35px;
          }
        }

        .heading {
          padding-bottom: 30px;

          h5 {
            color: #2f2f2f;
            font-family: "Readex Pro";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        ul {
          list-style: none;
          padding: 0px;
          padding-bottom: 26px;

          li {
            color: #707070;
            font-family: "Inter";
            font-size: 21.333px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 26px;
            cursor: pointer;

            a {
              color: #707070;
              font-family: "Inter";
              font-size: 21.333px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-bottom: 26px;
              cursor: pointer;

              text-decoration: none;
            }
          }
        }

        .newsletter-para {
          color: #707070;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 167.011%;
          /* 26.722px */
          letter-spacing: 0.64px;
        }

        .inputfield {
          display: flex;
          justify-content: space-between;
          width: 100%;

          input {
            // max-width: 339px;
            // width: 280px;
            // width: 100%;
            width: 339px;
            height: 59px;
            border-radius: 6px;
            background: #fff;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            border: none;
            padding-left: 10px;
            padding-right: 10px;

            &::placeholder {
              color: #c0c0c0;
              font-family: "Inter";
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .btn-sign {
            border-radius: 6px;
            background: #4176d4;
            padding: 16px 38px;
            // padding: 5px 10px;
            color: #fff;
            font-family: "Inter";
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: fit-content;
            border: none;
          }
        }

        .agree-content {
          max-width: 523px;
          padding-top: 14px;

          span {
            color: #707070;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 167.011%;
            /* 26.722px */
            letter-spacing: 0.64px;
          }
        }
      }
    }

    .footer-bottom {
      border-top: 1px solid #9d9d9d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px 100px 26px 100px;

      .left-side {
        display: flex;
        gap: 30px;

        .copy-right {
          color: #4f4f4f;
          font-family: "Inter";
          font-size: 18.667px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          margin: 0px;
        }

        .privacy {
          color: #707070;
          font-family: "Inter";
          font-size: 21.333px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .terms {
          color: #707070;
          font-family: "Inter";
          font-size: 21.333px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .right-side {
        display: flex;
        gap: 40px;
      }
    }
  }

  .chatbotimg {
    position: fixed;
    z-index: 99;
    right: 100px;
    bottom: 40px;
    width: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .footer-section {
    .footer-content {
      width: 100%;

      .footer {
        padding: 70px 50px 133px 50px;
        display: flex;
        gap: 70px;

        .first {
          // width: 25%;
          .first-content {
            max-width: 418px;

            .first-para {
              color: #5b5b5b;
              font-family: "Inter";
              font-size: 18.667px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.011%;
              /* 31.175px */
              letter-spacing: 0.747px;
              padding-top: 35px;
            }
          }

          .heading {
            padding-bottom: 30px;

            h5 {
              color: #2f2f2f;
              font-family: "Readex Pro";
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          ul {
            list-style: none;
            padding: 0px;
            padding-bottom: 26px;

            li {
              color: #707070;
              font-family: "Inter";
              font-size: 21.333px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-bottom: 26px;
              cursor: pointer;
            }
          }

          .newsletter-para {
            color: #707070;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 167.011%;
            /* 26.722px */
            letter-spacing: 0.64px;
          }

          .inputfield {
            display: flex;
            justify-content: space-between;
            width: 100%;

            input {
              // max-width: 339px;
              // width: 280px;
              // width: 100%;
              width: 339px;
              height: 59px;
              border-radius: 6px;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              border: none;
              padding-left: 10px;
              padding-right: 10px;

              &::placeholder {
                color: #c0c0c0;
                font-family: "Inter";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .btn-sign {
              border-radius: 6px;
              background: #4176d4;
              padding: 16px 38px;
              // padding: 5px 10px;
              color: #fff;
              font-family: "Inter";
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: fit-content;
              border: none;
            }
          }

          .agree-content {
            max-width: 523px;
            padding-top: 14px;

            span {
              color: #707070;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.011%;
              /* 26.722px */
              letter-spacing: 0.64px;
            }
          }
        }
      }

      .footer-bottom {
        border-top: 1px solid #9d9d9d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 20px 26px 20px;

        .left-side {
          display: flex;
          gap: 30px;

          .copy-right {
            color: #4f4f4f;
            font-family: "Inter";
            font-size: 18.667px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin: 0px;
          }

          .privacy {
            color: #707070;
            font-family: "Inter";
            font-size: 21.333px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .terms {
            color: #707070;
            font-family: "Inter";
            font-size: 21.333px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .right-side {
          display: flex;
          gap: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .footer-section {
    .footer-content {
      width: 100%;

      .footer {
        padding: 70px 20px 133px 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .first {
          // width: 25%;
          .first-content {
            max-width: 300px;

            .first-para {
              color: #5b5b5b;
              font-family: "Inter";
              font-size: 18.667px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.011%;
              /* 31.175px */
              letter-spacing: 0.747px;
              padding-top: 35px;
            }
          }

          .heading {
            padding-bottom: 30px;

            h5 {
              color: #2f2f2f;
              font-family: "Readex Pro";
              font-size: 22px !important;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          ul {
            list-style: none;
            padding: 0px;
            padding-bottom: 26px;

            li {
              color: #707070;
              font-family: "Inter";
              font-size: 21.333px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-bottom: 26px;
              cursor: pointer;
            }
          }

          .newsletter-para {
            color: #707070;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 167.011%;
            /* 26.722px */
            letter-spacing: 0.64px;
          }

          .inputfield {
            display: flex;
            justify-content: space-between;
            width: 100%;

            input {
              // max-width: 339px;
              // width: 280px;
              // width: 100%;
              width: 300px;
              height: 59px;
              border-radius: 6px;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              border: none;
              padding-left: 10px;
              padding-right: 10px;

              &::placeholder {
                color: #c0c0c0;
                font-family: "Inter";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .btn-sign {
              border-radius: 6px;
              background: #4176d4;
              padding: 16px 38px;
              // padding: 5px 10px;
              color: #fff;
              font-family: "Inter";
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: fit-content;
              border: none;
            }
          }

          .agree-content {
            max-width: 523px;
            padding-top: 14px;

            span {
              color: #707070;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.011%;
              /* 26.722px */
              letter-spacing: 0.64px;
            }
          }
        }
      }

      .footer-bottom {
        border-top: 1px solid #9d9d9d;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 20px 26px 20px;

        .left-side {
          display: flex;
          gap: 30px;

          .copy-right {
            color: #4f4f4f;
            font-family: "Inter";
            font-size: 18.667px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin: 0px;
          }

          .privacy {
            color: #707070;
            font-family: "Inter";
            font-size: 21.333px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .terms {
            color: #707070;
            font-family: "Inter";
            font-size: 21.333px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .right-side {
          display: flex;
          gap: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer-section {
    .footer-content {
      width: 100%;

      .footer {
        padding: 70px 10px 50px 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .first {
          text-align: center;
          // width: 100%;

          // width: 25%;
          .first-content {
            max-width: 400px;
            // max-width: 500px;
            // width: 100%;

            .first-para {
              color: #5b5b5b;
              font-family: "Inter";
              font-size: 18.667px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.011%;
              /* 31.175px */
              letter-spacing: 0.747px;
              padding-top: 35px;
            }
          }

          .heading {
            padding-bottom: 20px;

            h5 {
              color: #2f2f2f;
              font-family: "Readex Pro";
              font-size: 22px !important;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          ul {
            list-style: none;
            padding: 0px;
            padding-bottom: 0px;

            li {
              color: #707070;
              font-family: "Inter";
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-bottom: 16px;
              cursor: pointer;
            }
          }

          .newsletter-para {
            color: #707070;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 167.011%;
            /* 26.722px */
            letter-spacing: 0.64px;
          }

          .inputfield {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;

            input {
              // max-width: 339px;
              // width: 280px;
              width: 60%;
              // width: 240px;
              height: 49px;
              border-radius: 6px;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              border: none;
              padding-left: 10px;
              padding-right: 10px;

              &::placeholder {
                color: #c0c0c0;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .btn-sign {
              border-radius: 6px;
              background: #4176d4;
              padding: 12px 18px;
              // padding: 5px 10px;
              color: #fff;
              font-family: "Inter";
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: fit-content;
              border: none;
            }
          }

          .agree-content {
            max-width: 523px;
            padding-top: 14px;

            span {
              color: #707070;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 167.011%;
              /* 26.722px */
              letter-spacing: 0.64px;
            }
          }
        }
      }

      .footer-bottom {
        border-top: 1px solid #9d9d9d;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 26px 10px 20px 10px;

        .left-side {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;

          .copy-right {
            color: #4f4f4f;
            font-family: "Inter";
            font-size: 16.667px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin: 0px;
          }

          .privacy {
            color: #707070;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .terms {
            color: #707070;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .right-side {
          display: flex;
          gap: 40px;
          padding-top: 20px;
        }
      }
    }
  }
}
