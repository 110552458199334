.advicecard-section {
  .section-content {
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .vedio {
      width: 100%;
      display: flex;
      justify-content: center;

      video {
        width: 100%;
        height: 100%;
        max-width: 1300px;
      }
    }
  }

  .card-button {
    padding-left: 231px;
    padding-top: 70px;

    button {
      padding: 16px 32px;
      border-radius: 8px;
      background: var(
        --gradient,
        linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
      );
      color: #fff;
      font-family: "Inter";
      font-size: 1.146vw;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      border: none;
    }
  }

  h5 {
    color: #2b2b2b;
    font-family: "Readex Pro";
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -2.44px;
    text-align: center;
  }

  p {
    color: #707070;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    text-align: center;
  }
}

@media only screen and (max-width: 1600px) {
  .advicecard-section {
    .section-content {
      padding: 0px 80px 50px 80px;

      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // gap: 24px;
      .vedio {
        width: 100%;

        video {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-button {
      padding-left: 50px;
      padding-top: 70px;

      button {
        padding: 16px 32px;
        border-radius: 8px;
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        color: #fff;
        font-family: "Inter";
        font-size: 1.146vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .advicecard-section {
    h5 {
      font-size: 22px !important;
      letter-spacing: -0.14px;
    }

    .advicecard-p {
      font-size: 18px !important;
    }

    .inner-cardsection {
      padding-left: 10px;
      padding-right: 10px;
    }

    .pb-5 {
      // padding-bottom: 0.1rem !important;
    }

    .mb-5 {
      // margin-bottom: 0.1rem !important;
    }

    .section-content {
      padding: 10px 10px 0px 10px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }

    .card-button {
      text-align: center;
      padding-left: 0px;
      padding-top: 20px;
      padding-bottom: 40px;

      button {
        padding: 16px 32px;
        border-radius: 8px;
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        color: #fff;
        font-family: "Inter";
        font-size: 1.146vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
      }
    }
  }
}
